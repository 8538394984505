import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <div className="text-center mx-auto pt-32 space-y-4">
      <h1 className="text-blue-800 type-6xl">404: Page Not Found</h1>
      <p className="text-grey-500 type-base">
        Go back{" "}
        <Link to="/" className="underline">
          home
        </Link>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
